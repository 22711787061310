import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { always } from 'ramda';

import { HOME_ROUTE, LANDMARK_ROLES, ROUTE } from '@utils/constants';
import { footerSelectors } from '@modules/footer';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { useHeaderSublevel } from '@hooks/useUi';
import { SUBLEVEL } from '@components/header/header.constants';
import { useCustomer } from '@hooks/useCustomer';
import { useShippingCountry } from '@hooks/useShipping';
import { NewsletterFooter } from '@components/newsletterFooter';

import {
  Button,
  Container,
  Content,
  InstagramIcon,
  Link,
  LinkButton,
  LinkContent,
  LinkItem,
  Links,
  LinksList,
  MainLogo,
  SocialItem,
  SocialLink,
  SocialList,
} from './footer.styles';

export const Footer = () => {
  const intl = useIntl();
  const links = useSelector(footerSelectors.selectFooterLinks);
  const { openHeaderSublevel } = useHeaderSublevel();
  const isInstagram = !!links?.instagram;
  const { isAuthed } = useCustomer();
  const { shippingCountryCode } = useShippingCountry();
  const shippingLabel = intl.formatMessage(
    { id: 'footer.shippingLabel', defaultMessage: 'Shipping to. Currently selected {country}' },
    { country: shippingCountryCode }
  );
  const instagramLabel = intl.formatMessage({ id: 'footer.instagramLinkTitle', defaultMessage: 'Instagram' });

  const renderInstagramLink = renderWhenTrue(() => (
    <SocialItem>
      <SocialLink href={links?.instagram as string} target="_blank" title={instagramLabel} aria-label={instagramLabel}>
        <InstagramIcon />
      </SocialLink>
    </SocialItem>
  ));

  const renderSocialLinks = renderWhenTrue(() => (
    <LinkItem>
      <SocialList>{renderInstagramLink(isInstagram)}</SocialList>
    </LinkItem>
  ));

  const renderAccountLink = renderWhenTrueOtherwise(
    always(
      <LinkButton
        onClick={() => {
          window.history.pushState({}, '', ROUTE.ACCOUNT);
          window.location.href = ROUTE.ACCOUNT;
        }}
      >
        <Link>
          <LinkContent>
            <FormattedMessage id="footer.account" defaultMessage="Account" />
          </LinkContent>
        </Link>
      </LinkButton>
    ),
    always(
      <Button onClick={() => openHeaderSublevel({ sublevel: SUBLEVEL.ACCOUNT })}>
        <FormattedMessage id="footer.account" defaultMessage="Account" />
      </Button>
    )
  );

  return (
    <Container role={LANDMARK_ROLES.CONTENT_INFO}>
      <NewsletterFooter />
      <Content>
        <Links>
          <LinksList>
            <LinkItem>
              <LinkButton
                onClick={() => {
                  window.history.pushState({}, '', ROUTE.HOMEPAGE);
                  window.location.href = ROUTE.HOMEPAGE;
                }}
              >
                <Link>
                  <MainLogo>
                    <FormattedMessage id="footer.platform" defaultMessage="Platform: " />
                  </MainLogo>
                </Link>
              </LinkButton>
            </LinkItem>

            <LinkItem>
              <LinkButton
                onClick={() => {
                  window.history.pushState({}, '', ROUTE.ABOUT);
                  window.location.href = ROUTE.ABOUT;
                }}
              >
                <Link>
                  <LinkContent>
                    <FormattedMessage id="footer.about" defaultMessage="About Platform" />
                  </LinkContent>
                </Link>
              </LinkButton>
            </LinkItem>
            <LinkItem>
              <LinkButton
                onClick={() => {
                  window.history.pushState({}, '', ROUTE.FAQ);
                  window.location.href = ROUTE.FAQ;
                }}
              >
                <Link>
                  <LinkContent>
                    <FormattedMessage id="footer.faq" defaultMessage="FAQ" />
                  </LinkContent>
                </Link>
              </LinkButton>
            </LinkItem>
            <LinkItem>
              <LinkButton
                onClick={() => {
                  window.history.pushState({}, '', ROUTE.GALLERIES);
                  window.location.href = ROUTE.GALLERIES;
                }}
              >
                <Link>
                  <LinkContent>
                    <FormattedMessage id="footer.galleries" defaultMessage="Galleries" />
                  </LinkContent>
                </Link>
              </LinkButton>
            </LinkItem>
            <LinkItem>
              <LinkButton
                onClick={() => {
                  window.history.pushState({}, '', ROUTE.CONSULTING);
                  window.location.href = ROUTE.CONSULTING;
                }}
              >
                <Link>
                  <LinkContent>
                    <FormattedMessage id="footer.consulting" defaultMessage="Consulting" />
                  </LinkContent>
                </Link>
              </LinkButton>
            </LinkItem>
            <LinkItem>
              <LinkButton
                onClick={() => {
                  window.history.pushState({}, '', ROUTE.PRIVATE_CLIENT);
                  window.location.href = ROUTE.PRIVATE_CLIENT;
                }}
              >
                <Link>
                  <LinkContent>
                    <FormattedMessage id="footer.privateClient" defaultMessage="Private Client" />
                  </LinkContent>
                </Link>
              </LinkButton>
            </LinkItem>
          </LinksList>

          <LinksList>
            <LinkItem>{renderAccountLink(isAuthed)}</LinkItem>
            <LinkItem>
              <LinkButton
                onClick={() => {
                  window.history.pushState({}, '', ROUTE.CONTACT_US);
                  window.location.href = ROUTE.CONTACT_US;
                }}
              >
                <Link>
                  <LinkContent>
                    <FormattedMessage id="footer.contact" defaultMessage="Contact Us" />
                  </LinkContent>
                </Link>
              </LinkButton>
            </LinkItem>
            <LinkItem>
              <LinkButton
                onClick={() => {
                  window.history.pushState({}, '', ROUTE.SHIPPING_RETURNS);
                  window.location.href = ROUTE.SHIPPING_RETURNS;
                }}
              >
                <Link>
                  <LinkContent>
                    <FormattedMessage id="footer.shippingReturns" defaultMessage="Shipping & Returns" />
                  </LinkContent>
                </Link>
              </LinkButton>
            </LinkItem>
            <LinkItem>
              <LinkButton
                onClick={() => {
                  window.history.pushState({}, '', ROUTE.TERMS_AND_CONDITIONS);
                  window.location.href = ROUTE.TERMS_AND_CONDITIONS;
                }}
              >
                <Link>
                  <LinkContent>
                    <FormattedMessage id="footer.termsAndConditions" defaultMessage="User Terms" />
                  </LinkContent>
                </Link>
              </LinkButton>
            </LinkItem>
            <LinkItem>
              <LinkButton
                onClick={() => {
                  window.history.pushState({}, '', ROUTE.PRIVACY_POLICY);
                  window.location.href = ROUTE.PRIVACY_POLICY;
                }}
              >
                <Link>
                  <LinkContent>
                    <FormattedMessage id="footer.privacyPolicy" defaultMessage="Privacy Policy" />
                  </LinkContent>
                </Link>
              </LinkButton>
            </LinkItem>
            {renderSocialLinks(isInstagram)}
          </LinksList>
        </Links>
      </Content>
    </Container>
  );
};
